import { makeStyles } from '@mui/styles';
import { divIcon, LatLngExpression, LeafletMouseEvent, Map as LeafletMap } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { Marker, Polygon, Polyline } from 'react-leaflet';

export interface BoundingBox {
  start: [number, number];
  end: [number, number];
  min: [number, number];
  max: [number, number];
}
export interface Line {
  a: [number, number];
  b: [number, number];
  length: number;
}

function geodesicArea(locations: [number, number][]) {
  const pointsCount = locations.length;
  const d2r = Math.PI / 180;
  let area = 0.0;
  let p1; let p2;
  if (pointsCount > 2) {
    for (let i = 0; i < pointsCount; i += 1) {
      p1 = locations[i];
      p2 = locations[(i + 1) % pointsCount];
      area += ((p2[1] - p1[1]) * d2r) *
        (2 + Math.sin(p1[0] * d2r) + Math.sin(p2[0] * d2r));
    }
    area = area * 6378137.0 * 6378137.0 / 2.0;
  }

  return Math.abs(area);
}

function distance(a: [number, number], b: [number, number]): number {
  const R = 6371e3;
  const halfCircle = Math.PI/180;
  const φ1 = a[0] * halfCircle;
  const φ2 = b[0] * halfCircle;
  const Δφ = (b[0]-a[0]) * halfCircle;
  const Δλ = (b[1]-a[1]) * halfCircle;

  const p1 = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(p1), Math.sqrt(1-p1));
  return R * c;
}

export function useMeasurementTool(map: LeafletMap | undefined) {
  const [selected, setSelected] = useState(false);
  const [box, setBox] = useState<BoundingBox & {length: number} | undefined>(undefined)
  const [lines, setLines] = useState<Line[]>([]);
  const [phase, setPhase] = useState<number>(0);

  useEffect(() => {
    map?.removeEventListener('click');
    map?.removeEventListener('mousemove');
    if(selected) {
      map?.on('click', (e: LeafletMouseEvent) => {
        if(e.containerPoint.x < map?.getSize().x - 50) {
          if (phase === 1 || phase === 3) {
            setLines([]);
            setBox({
              start: [e.latlng.lat, e.latlng.lng],
              end: [e.latlng.lat, e.latlng.lng],
              min: [e.latlng.lat, e.latlng.lng],
              max: [e.latlng.lat, e.latlng.lng],
              length: distance([e.latlng.lat, e.latlng.lng], [e.latlng.lat, e.latlng.lng])
            });
            setPhase(2);
          } else if (phase === 2) {
            if ((box?.length ?? 0) < 1) {
              setBox(undefined);
              setPhase(3);
            } else {
              setLines(old => [...old, {a: box?.start!, b: box?.end!, length: distance(box?.start!, box?.end!)}]);
              setBox({
                start: box?.end!,
                end: box?.end!,
                min: box?.end!,
                max: box?.end!,
                length: 0
              });
            }
          }
        }
      });
      map?.on('mousemove', (e: LeafletMouseEvent) => {
        if(phase === 2 && box) {
          setBox(b => {
            if(b) {
              const startLat = Math.min(b!.start[0], e.latlng.lat);
              const startLong = Math.min(b!.start[1], e.latlng.lng);
              const endLat = Math.max(b!.start[0], e.latlng.lat);
              const endLong = Math.max(b!.start[1], e.latlng.lng);
              return {
                start: b!.start,
                end: [e.latlng.lat, e.latlng.lng],
                min: [startLat, startLong],
                max: [endLat, endLong],
                length: distance([startLat, startLong], [endLat, endLong])
              };
            }
            return b;
          });
        }
      });
    }
  }, [map, selected, phase, box]);
  return {
    selected,
    lines,
    box,
    onClick: () => {
      setBox(undefined);
      if(!selected) {
        setPhase(1);
      } else {
        setLines([]);
      }
      setSelected(s => !s)
    }
  }
}

const useStyles = makeStyles(() => ({
  measurementText: {
    fontSize: '1.2em',
    padding: '.1em .3em',
    backgroundColor: '#dddbd699',
    border: '1px solid #b1afa8',
    borderRadius: '50vh',
    width: 'fit-content',
    wordWrap: 'normal',
    whiteSpace: 'nowrap'
  },
  measurementTextBox: {
    backgroundColor: "transparent"
  }
}));

export function MeasureLines({drawing, lines}: {drawing: BoundingBox & {length: number} | undefined, lines: Line[]}) {
  const classes = useStyles();

  function center(line: Line): LatLngExpression {
    return {lat: (line.b[0] + line.a[0]) / 2, lng: (line.b[1] + line.a[1]) / 2}
  }

  const allLines: Line[] = [...lines, ...(drawing ? [{a: drawing.start, b: drawing.end, length: drawing.length}] : [])]
  const positions = allLines.length > 0 ? [({
    lat: allLines[0].a[0]!,
    lng: allLines[0].a[1]!
  }), ...allLines.map(it => ({lat: it.b[0]!, lng: it.b[1]!}))] : [];

  const linesWithLast = allLines.length > 0 ? [...allLines, {a: allLines[allLines.length-1].b, b: allLines[0].a, length: distance(allLines[allLines.length-1].b, allLines[0].a)}] : allLines;

  const area = geodesicArea(positions.map(it => [it.lat, it.lng]));
  const areaCenter = positions.length > 0 ? [positions.reduce((prev, it) => it.lat + prev, 0) / positions.length, positions.reduce((prev, it) => it.lng + prev, 0) / positions.length] : undefined;
  return (
    <>
      <Polygon className="map-cursor-control" pathOptions={{color: '#b1afa8'}} positions={positions}/>
      {linesWithLast.map(it => <>
        <Polyline className="map-cursor-control"  pathOptions={{color: '#0000FF55', dashArray: [20, 10], lineCap: "round"}}
                  positions={[{lat: it.a[0]!, lng: it.a[1]!}, {lat: it.b[0]!, lng: it.b[1]!}]}/>
        <Marker draggable position={center(it)} icon={divIcon({
          className: classes.measurementTextBox,
          html: `<div class="${classes.measurementText}">${it.length.toFixed(0)}m</div>`
        })}/>
      </>)}
      {areaCenter && <Marker draggable position={{lat: areaCenter[0], lng: areaCenter[1]}} icon={divIcon({
        className: classes.measurementTextBox,
        html: `<div class="${classes.measurementText}">Area: ${area.toFixed(2)}m</div>`
      })}/>}
    </>
  )
}
