import {ImageDetail} from "@hexlabsio/dasha-categories-sdk";
import {ImageDetail as MarkerImageDetail} from "@hexlabsio/dasha-markers-sdk";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default function useIcons() {
    function imgType(type: string): string {
        if(type.toUpperCase() ==='SVG') return 'svg+xml';
        if(type === 'ICO') return 'ico';
        return 'png';
    }
    function dataUrlFrom(icon?: MarkerImageDetail): string {
        if(icon?.type === 'DATA') {
            return `data:image/${imgType(icon.imageType)};charset=UTF-8;base64,${icon.dataUrl}`
        }
        return '';
    }
    function iconFrom(icon: ImageDetail): JSX.Element {
        if(icon.type === 'FONT') return <FontAwesomeIcon icon={{iconName: icon.name as any, prefix: 'fas'}}/>;
        if(icon.type === 'DATA') return <img width="100%" alt="icon" src={`data:image/${imgType(icon.imageType)};charset=UTF-8;base64,${icon.dataUrl}`}/>
        return <></>;
    }
    return {
        iconFrom,
        dataUrlFrom
    }
}
