import {useRecoilState, useSetRecoilState} from "recoil";
import {Box, Button, CircularProgress} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {triggerActionIdState} from "../../hooks/use-button-action";
import {searchRadius} from "../../hooks/shared-state";
import {buttonsState} from "../../hooks/category/category-state";

function SearchArea({category}: {category: string}): JSX.Element {
    const [buttons, ] = useRecoilState(buttonsState({category}));
    const searchArea = useSetRecoilState(searchRadius);
    const [triggerActionIds, setTriggerActionIds] = useRecoilState(triggerActionIdState);
    const selected = buttons.filter(it => it.selected);
    const searchable = selected.filter(it => it.actions.some(action => !!action.locationType));
    const maxRadius = searchable.reduce((max, next) => Math.max(max, ...next.actions.map(it => it.locationType?.type === 'RADIAL' ? it.locationType.radius ?? 0 : 0)), 0)
    return searchable.length > 0 && (
        <Box position="relative" sx={{
            zIndex: 30000,
            bottom: 0,
            marginTop: '-10vh',
            width: '100%',
            display: 'flex',
            pointerEvents: 'none'
        }}>
            <Box flex={1} />
            <Box>
                <Button onMouseEnter={() => searchArea(maxRadius)} onMouseLeave={() => searchArea(0)} variant="contained" sx={{pointerEvents: 'all', color: 'white'}} onClick={() => {
                    if(triggerActionIds.length === 0) {
                        setTriggerActionIds(searchable.map(it => it.identifier));
                    }
                }}>
                    {triggerActionIds.length > 0 ? <CircularProgress size={24} sx={{marginRight:1, color: 'white'}}/> : <FontAwesomeIcon style={{marginRight: '1em'}} icon={faSearch}/>}
                    Search
                </Button>
            </Box>
            <Box flex={1}/>
        </Box>
    ) || <></>;
}

export default SearchArea;
