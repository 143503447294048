/* eslint-disable */

import { CarbonRequest } from '@hexlabsio/dasha-categories-sdk';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import isEqual from 'lodash.isequal';
import React, { memo, PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { atom, selector, useRecoilState } from 'recoil';
import background1 from '../../../assets/bg.jpg'
import background2 from '../../../assets/bg_two.jpg'
import background3 from '../../../assets/bg_three.jpg'
import background4 from '../../../assets/bg_four.jpg'
import background5 from '../../../assets/result_bg.jpg'
import backArrow from '../../../assets/back-arrow.svg';
import nextArrow from '../../../assets/next_icon.svg';
import useCalculatorFields from '../../../hooks/calculator/calculator-state';
import { authenticatedAxios } from '../../../hooks/sdks';

const useStyles = makeStyles(() => ({
  "app_box": {
    transition: 'bottom 1s',
    width: '500px',
    position: 'relative',
    maxHeight: '75vh',
    minHeight: '75vh',
    color: 'white',
    marginLeft: '1em',
    display: 'flex',
    backgroundColor: 'transparent',
    "& button": {
      border: 0
    },
    "& h1, h2, h3, h4, h5, h6": {
      margin:0,
      padding:0,
      fontWeight:"normal",
    }
  },
  "bg_image": {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
    "& > img": {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    "&::after": {
      content: '""',
      position: 'absolute',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      opacity: 0.85,
      background: 'radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)'
    }
  },
  "bodybox": {
    position: 'relative',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  "container_box": {
    width: '100%',
    padding: '0 25px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "header_inner": {
    display: 'flex',
    width: '100%',
    height: '90px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '30px',
    "& h1": {
      fontSize: '22px',
      flex: 1,
      textAlign: 'center'
    }
  },
  "back_to_page": {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    marginTop: '0.5em',
    background: 'url(../images/back-arrow.svg) center no-repeat',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    }
  },
  "content_box": {
    display: 'flex',
    width: '100%',
    paddingTop: '54px',
    paddingBottom: '30px',
    flex: 1,
    overflowY: 'auto'
  },
  "formGroup": {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column'
  },
  "section_title_name": {
    fontSize: '26px',
    color: '#FFCC57',
    fontWeight: 500
  },
  "row": {
    display: 'flex',
    flexDirection: 'column'
  },
  "col-md-12": {
    width: '100%'
  },
  "form-control": {
    border: 0,
    height: '52px',
    padding: '0 10px',
    fontSize: '18px',
    borderRadius: '8px',
    outline: 0
  },
  "form-label": {
    fontSize: '18px',
    marginBottom: '12px'
  },
  "min_height_box": {
    display: 'flex',
    flexDirection: 'column',
    "flex": 1
  },
  "next_btn": {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    background: '#FFCC57',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& > img": {
      width: '30px',
      height: '30px'
    },
    '&:hover': {
      backgroundColor: '#DFAF41',
      cursor: 'pointer'
    }
  }
}));

function Form1({next}: {next: (data: any) => void}) {
  const classes = useStyles();
  const {register, handleSubmit } = useForm()

  return (
    <form onSubmit={handleSubmit((data) => {
    next(data);
    }, errors => console.log(errors))} className={classes.container_box}>
      <div className={classes.min_height_box}>
          <h3 className={classes.section_title_name} style={{marginBottom: '24px'}}>Input Data</h3>
          <div className={classes.row}>
            <div className={classes['col-md-12']}>
              <div className={classes.formGroup}>
                <label className={classes['form-label']}>Road Scheme</label>
                <input {...register('roadSchema')} type="text" name="roadSchema" className={classes['form-control']}/>
              </div>
            </div>
            <div className={classes['col-md-12']}>
              <div className={classes.formGroup}>
                <label className={classes['form-label']}>Address</label>
                <input {...register('address')} type="text" name="address" className={classes['form-control']}/>
              </div>
            </div>
          </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button type={"submit"} className={classes.next_btn}>
          <img src={nextArrow} alt="next"/>
        </button>
      </div>
    </form>
  )
}

function Form2({next}: {next: (data: any) => void}) {
  const classes = useStyles();
  const {register, handleSubmit } = useForm()

  return (
    <form onSubmit={handleSubmit((data) => {
      next(data);
    }, errors => console.log(errors))} className={classes.container_box}>
      <div className={classes.min_height_box}>
        <h3 className={classes.section_title_name} style={{marginBottom: '24px'}}>Asphalt Layer Dimensions</h3>
        <div className={classes.row}>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Length ( m )</label>
              <input {...register('length', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Width ( m )</label>
              <input {...register('width', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Depth ( mm )</label>
              <input {...register('depth', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Tonnage of Planing ( tonnes )</label>
              <input {...register('tonnageOfPlanning', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Waste / Surplus Asphalt ( tonnes )</label>
              <input {...register('wastesurplusAsphalt', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Number of Paving Machines on Site</label>
              <input {...register('numberOfPavingMachine', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button type={"submit"} className={classes.next_btn}>
          <img src={nextArrow} alt="next"/>
        </button>
      </div>
    </form>
  )
}

function Form3({next}: {next: (data: any) => void}) {
  const classes = useStyles();
  const {register, handleSubmit } = useForm()

  return (
    <form onSubmit={handleSubmit((data) => {
      next(data);
    }, errors => console.log(errors))} className={classes.container_box}>
      <div className={classes.min_height_box}>
        <h3 className={classes.section_title_name} style={{marginBottom: '24px'}}>Transport Emissions</h3>
        <div className={classes.row}>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Distance from Hot Mix facility to Site ( Km )</label>
              <input {...register('distanceFromHotMix', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Truck Capacity ( tonnes )</label>
              <input {...register('trackCapacity', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button type={"submit"} className={classes.next_btn}>
          <img src={nextArrow} alt="next"/>
        </button>
      </div>
    </form>
  )
}

function Form4({next}: {next: (data: any) => void}) {
  const classes = useStyles();
  const {register, handleSubmit } = useForm()

  return (
    <form onSubmit={handleSubmit((data) => {
      next(data);
    }, errors => console.log(errors))} className={classes.container_box}>
      <div className={classes.min_height_box}>
        <h3 className={classes.section_title_name} style={{marginBottom: '24px'}}>Low Temperature Asphalt</h3>
        <div className={classes.row}>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Percentage of Warm Mix  Asphalt ( % )</label>
              <input {...register('perOfWarmMix', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
          <h3 className={classes.section_title_name} style={{marginBottom: '24px'}}>Recycled Asphalt Content</h3>
          <div className={classes['col-md-12']}>
            <div className={classes.formGroup}>
              <label className={classes['form-label']}>Percentage of RAP in Asphalt Mix ( % )</label>
              <input {...register('percentageOfRAPInAsphaltMix', {valueAsNumber: true})} type="number" className={classes['form-control']}/>
            </div>
          </div>
        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button type={"submit"} className={classes.next_btn}>
          <img src={nextArrow} alt="next"/>
        </button>
      </div>
    </form>
  )
}

function CarbonForm({children, open, hasBack, back, background, title}: PropsWithChildren<{open: boolean, title: string; background: string; hasBack: boolean; back: () => void}>) {
  const classes = useStyles();
  return (
    <div className={classes.app_box} style={{bottom: open ? '0' : '-100vh' }}>
      <div className={classes.bg_image}><img src={background} alt="image"/></div>
      <div className={classes.bodybox}>
        <header>
          <div className={classes.container_box}>
            <div className={classes.header_inner}>
              <button onClick={() => back()} className={classes.back_to_page} style={hasBack ? {} : {display: 'none'}}>
                <img src={backArrow} alt="back"/>
              </button>
              <h1 style={{fontSize: '22px'}}>{title}</h1>
            </div>
          </div>
        </header>
        <div className={classes.content_box}>
          {children}
        </div>
      </div>
    </div>
  )
}

function Results({data}: {data: CarbonRequest}): JSX.Element {
  const classes = useStyles();
  const result = useCalculatorFields(data);
  const isLoading = result.state !== 'hasValue'

  return <Box className={classes.container_box} alignItems="center">
    <Box display="flex" justifyContent="center">
      <h3 style={{fontSize: '24px'}}>Tonnes CO2 required to be Offset</h3>
    </Box>
    <Box sx={{
      width: '198px',
      height: '198px',
      border: 'solid 4px #FFCC57',
      borderRadius: '50%',
      color: '#fff',
      textAlign: 'center',
      lineHeight: '190px',
      fontSize: '80px',
      fontWeight: 600,
      marginTop:' 45px'
    }}>
      { !isLoading ? result?.getValue()?.netTotalCo2e ?? '...' : '...' }
    </Box>
    <Box sx={{width: '100%', marginTop: '98px'}}>
      <Box sx={{backgroundColor: '#FFCC57', width: '90%', height: '5px', borderRadius: '10px', marginBottom: '8px'}}/>
      <Box display="flex" fontSize="18px" marginBottom="40px">
        <Box flex={1}>Cost of Local Offset</Box>
        <Box>{'£0.00'}</Box>
      </Box>
      <Box sx={{backgroundColor: '#66DE58', width: '80%', height: '5px', borderRadius: '10px', marginBottom: '8px'}}/>
      <Box display="flex" fontSize="18px" marginBottom="40px">
        <Box flex={1}>Cost of International Offset</Box>
        <Box>{'£0.00'}</Box>
      </Box>
    </Box>

  </Box>
}

export const carbonOpen = atom({key: 'carbonOpenState', default: false});

export function CarbonForms(): JSX.Element {
  const [roadScheme, setRoadScheme] = useState<any>(undefined);
  const [asphalt, setAsphalt] = useState<any>(undefined);
  const [transport, setTransport] = useState<any>(undefined);
  const [others, setOthers] = useState<any>(undefined);
  const [isOpen] = useRecoilState(carbonOpen);
  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setRoadScheme(undefined);
        setAsphalt(undefined);
        setTransport(undefined);
        setOthers(undefined);
      }, 1000)
    }
  }, [isOpen])
  function currentForm() {
    if(!roadScheme) return <Form1 next={data => {setRoadScheme(data)}}/>;
    if(!asphalt) return <Form2 next={data => {setAsphalt(data)}}/>;
    if(!transport) return <Form3 next={data => {setTransport(data)}}/>;
    if(!others) return <Form4 next={data => {setOthers(data)}}/>;
    return <Results data={{...roadScheme, ...asphalt, ...transport, ...others}}/>
  }
  function background(): string {
    if(!roadScheme) return background1;
    if(!asphalt) return background2;
    if(!transport) return background3;
    if(!others) return background4;
    return background5;
  }
  function back() {
    if(others) setOthers(undefined);
    else if(transport) setTransport(undefined);
    else if(asphalt) setAsphalt(undefined);
    else if(roadScheme) setRoadScheme(undefined);
  }
  return (
    <CarbonForm open={isOpen} title={!!others ? 'Results' : 'Calculate Carbon'} background={background()} back={() => back()} hasBack={!!roadScheme}>
      { currentForm() }
    </CarbonForm>
  )
}

export default memo(CarbonForms, isEqual);
