import {Box, TextField, TextFieldProps, Typography} from "@mui/material";
import React from "react";

export default function Input({label, placeholder, textProps}: {label: string, placeholder: string, textProps: TextFieldProps}): JSX.Element {
  return (
    <Box width="100%" mt={2}>
      <Typography variant="body2" pl={2} sx={{ color: (t) => t.palette.primary.main, paddingBottom: (t) => t.spacing(1) }}>
        {label}
      </Typography>
      <TextField {...textProps} sx={{width:'100%'}} variant="outlined" placeholder={placeholder} />
    </Box>
  )
}
