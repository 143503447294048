import {MarkerMeta} from "@hexlabsio/dasha-markers-sdk";
import {useRecoilValue} from "recoil";
import * as geojson from "geojson";
import React from "react";
import {markerPropertiesState} from "../../hooks/markers/marker-state";
import Properties from "./properties";
import {layerPropertiesState} from "../../hooks/layers/layer-state";

export function MarkerPopup({category, collection, marker}: { marker: MarkerMeta; category: string; collection: string;}): JSX.Element {
    const markerData = useRecoilValue(markerPropertiesState({category, collection, identifier: marker.identifier}));
    return (
        <Properties title={marker.title} properties={markerData?.markerProperties} data={markerData?.data as any} category={category}/>
    )
}

export function FeaturePopup({ category, collection, feature }: {category: string, collection: string, feature: geojson.Feature<geojson.GeometryObject,  any> | undefined}): JSX.Element {
    const layerData = useRecoilValue(layerPropertiesState({category, collection, identifier: feature?.id?.toString() ?? ''}));
    return (
        <Properties title={feature?.properties.title ?? ''} properties={(layerData as any)?.properties} data={layerData?.data} category={category} />
    )
}