import React  from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {ControlOptions} from "leaflet";
import {Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDrawPolygon, faRulerHorizontal, faCloud} from "@fortawesome/free-solid-svg-icons";
import {ZoomControl} from "react-leaflet";
import { useRecoilState } from 'recoil';
import { carbonOpen } from './carbon/calculator';

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

function SelectionControl({ onClick, selected }: { selected: boolean; onClick: () => void}): JSX.Element {
    return (
        <Box className="leaflet-control leaflet-bar" style={{
                border: selected ? '2px solid red' : '2px solid rgba(0,0,0,0.2)',
                color: selected ? '#fe7979' : '#333333'
            }} sx={{
                width: '34px',
                height: '34px',
                fontSize: '21px',
                backgroundColor: 'white'
            }}>
                <Box title="Search Area" onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick();
                }} onDoubleClick={e => {e.stopPropagation(); e.preventDefault()}} pl={0.6} sx={{cursor: 'pointer'}}>
                    <FontAwesomeIcon icon={faDrawPolygon}/>
                </Box>
            </Box>
    )
}


function MeasurementControl({ onClick, selected }: { selected: boolean; onClick: () => void}): JSX.Element {
    return (
        <Box className="leaflet-control leaflet-bar" style={{
            border: selected ? '2px solid red' : '2px solid rgba(0,0,0,0.2)',
            color: selected ? '#fe7979' : '#333333'
        }} sx={{
            width: '34px',
            height: '34px',
            fontSize: '16px',
            paddingTop: '2px',
            backgroundColor: 'white',
            marginTop: '1px'
        }}>
            <Box title="Search Area" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }} onDoubleClick={e => {e.stopPropagation(); e.preventDefault()}} pl={0.6} sx={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faRulerHorizontal}/>
            </Box>
        </Box>
    )
}

function CarbonControl(): JSX.Element {
  const [isOpen, setIsOpen] = useRecoilState(carbonOpen);

  return (
    <Box className="leaflet-control leaflet-bar" style={{
      border: isOpen ? '2px solid red' : '2px solid rgba(0,0,0,0.2)',
      color: isOpen ? '#fe7979' : '#333333'
    }} sx={{
      width: '34px',
      height: '34px',
      fontSize: '16px',
      paddingTop: '2px',
      backgroundColor: 'white',
      marginTop: '1px'
    }}>
      <Box title="Carbon Calculator" onClick={(e) => {
        e.stopPropagation();
        setIsOpen(open => !open);
      }} onDoubleClick={e => {e.stopPropagation(); e.preventDefault()}} pl={0.6} sx={{cursor: 'pointer'}}>
        <FontAwesomeIcon icon={faCloud}/>
      </Box>
    </Box>
  )
}


function MapControls({ position, selection, measure }: ControlOptions & { selection: { selected: boolean; onClick: () => void }, measure: { selected: boolean; onClick: () => void }}): JSX.Element {
    const positionClass =
        (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (
        <>
            <ZoomControl position={position}/>
            <Box className={positionClass} sx={{marginTop: '84px', pointerEvents: 'all'}} >
                <SelectionControl selected={selection.selected} onClick={selection.onClick} />
                <MeasurementControl selected={measure.selected} onClick={measure.onClick} />
                <CarbonControl />
            </Box>
        </>
    )
}

export default MapControls
