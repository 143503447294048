import {useRecoilValue} from "recoil";
import {categorySdk} from "../sdks";
import {jwtToken} from "../user/auth-state";
import {CollectionResult} from "../shared-state";

export default function useFiles(category: string) {
    const jwt = useRecoilValue(jwtToken)!;
    return {
        async getFile(fileName: string): Promise<CollectionResult>{
            try {
                const data = await categorySdk(jwt).getFile({ identifier: category, fileName });
                if (data.statusCode === 200) {
                    window.open(data.result, '_blank');
                    return {reason: 'NOT_COLLECTION'}
                }
            } catch(e) {
                return {reason: 'ERROR'}
            }
            return {reason: 'ERROR'}
        }
    }
}