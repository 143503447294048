import {Box} from "@mui/material";
import isEqual from "lodash.isequal";
import React, {memo, useEffect} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {Map as LeafletMap} from 'leaflet';
import Draggable from 'react-draggable';
import Map from "../map";
import CarbonForm1, { carbonOpen } from '../map/carbon/calculator';
import Sidebar from "../sidebar";
import DataPanel from "../data";
import {buttonsState, categoryState, shownCollections, useSelectedCategory} from "../../hooks/category/category-state";
import useButtonAction from "../../hooks/use-button-action";


function Dasha({onLoad}: {onLoad: (map: LeafletMap) => void; }): JSX.Element {
    const category = useSelectedCategory();
    const identifier = category?.identifier ?? '';
    const fullCategory = useRecoilValue(categoryState(identifier));
    const buttons = useRecoilValue(buttonsState({category: identifier}));
    const buttonActions = useButtonAction(identifier);
    const [isOpen] = useRecoilState(carbonOpen);
    useEffect(() => {
        shownCollections().forEach(({id, ...searchArea}) => {
            const button = buttons.find(it => it.identifier === id);
            if(button) {
                buttonActions.clicked({...button, selected: false}, searchArea)()
            }
        })
    }, [category])
    return (
        <Box sx={{height: '100%', display: 'flex'}}>
            <Draggable axis="x">
                <Box sx={{position: 'fixed', bottom: 0, left: '280px', zIndex: isOpen ? 1000 : -10, borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}}>
                    <CarbonForm1/>
                </Box>
            </Draggable>
            {category && <><Sidebar category={category?.identifier ?? ''}/>
          <Box ml={35} mt="64px" flex={1} sx={{height: 'calc(100%-64px)', display: 'flex', flexDirection: 'row'}}>
              <Map isStatic={false} centre={fullCategory?.centre ? [fullCategory?.centre.latitude, fullCategory?.centre.longitude] : [54,-1]} zoom={fullCategory?.zoom ?? 13} onLoad={onLoad} category={category?.identifier ?? ''}/>
              <DataPanel category={category?.identifier ?? ''}/>
          </Box></> }
        </Box>
    );
}

export default memo(Dasha, isEqual);
