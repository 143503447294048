import React, {memo, useEffect, useState} from 'react';
import isEqual from "lodash.isequal";
import {Box, CircularProgress, TextField} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {DataCollection, DataPart, TableData, TableItem} from "@hexlabsio/dasha-layers-sdk";
import { faExclamationTriangle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {dataCollectionState} from "../../hooks/data/data-service";
import {
    buttonState,
    collectionHighlight, useShownCollections
} from "../../hooks/category/category-state";
import useIcons from "../../hooks/use-icons";
import useButtonAction from "../../hooks/use-button-action";


function Row({row, index, category, buttonIdentifier}: {category: string; index: number; row: TableItem; buttonIdentifier: string}): JSX.Element {
    const button = useRecoilValue(buttonState({category, identifier: row.identifier!}));
    const setHighlight = useSetRecoilState(collectionHighlight);
    const {clicked} = useButtonAction(category);
    const active = row.actions.length > 0;
    return (
        <Box key={row.identifier} display="flex" alignItems="center"
             onClick={() => active && clicked(button)(row.actions, buttonIdentifier)}
             onMouseEnter={() => button.selected && setHighlight([button, row.identifier!])}
             onMouseLeave={() => setHighlight(old => old?.[1] === row.identifier ? undefined : old)}
             sx={{
            cursor: active ? 'pointer' : 'default',
                 borderLeft: t => button.selected ? `4px solid ${t.palette.primary.main}`: '0',
            bgcolor: t => button.selected && t.palette.grey[200] || (index % 2 === 0 ? t.palette.background.paper : t.palette.background.default),
            ...(active ? {'&:hover': {bgcolor: t => t.palette.grey[button.selected ? 300 : 200]}} : {})
        }}>
            {row.row.map((col, i) => (
                <Box pl={2} pr={2} display="flex" alignItems="center" flex={1} key={`row-${row.identifier}-${col}`}>
                    {i === 0 && ((button.loading && <CircularProgress size={16} sx={{mr: 2}}/>) || (button.error && <Box mr={2} sx={{color: t => t.palette.error.main}}><FontAwesomeIcon icon={faExclamationTriangle}/></Box>))}{col}
                </Box>
            ))}
        </Box>
    );
}

function Table({table, category, buttonIdentifier}: {category: string; table: TableData; buttonIdentifier: string}): JSX.Element {
    const [filter, setFilter] = useState<string>('');
    function include(row: TableItem): boolean {
        return filter === '' || row.row.some(text => text.toLowerCase().match(filter.toLowerCase()));
    }
    return (
        <Box display="flex" flexDirection="column" pt={2} pb={2} mb={2} border="1px solid whitesmoke" borderRadius="20px">
            {table.searchable && <TextField  label="Filter" variant="outlined" sx={{ml: 2, typography: 'h2', width: '400px', mt: 2, mb:2}} value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filter"/>}
            <Box display="flex" alignItems="center">{table.headers.map(it => <Box flex={1} mb={2} pl={2} pr={2} fontWeight={500} key={`header-${it}`}>{it}</Box>)}</Box>
            {table.rows.map((row, index) => include(row) && <Row buttonIdentifier={buttonIdentifier} category={category} row={row} index={index}/>)}
        </Box>
    )
}

function Part({part, category, buttonIdentifier}: { part: DataPart; category: string; buttonIdentifier: string }): JSX.Element {
    const {dataUrlFrom} = useIcons();
    if(part.type === 'IMAGE'){ return <Box m={2}><img width="100%" alt={part.altText} src={dataUrlFrom({type: 'DATA', dataUrl: part.src, imageType: 'PNG'})}/></Box>}
    if(part.type === 'TEXT') { return <Box width="100%" typography="h1" pb={2}>{part.text}</Box>}
    return <Table buttonIdentifier={buttonIdentifier} category={category} table={part}/>
}

export function Panel({dataCollection, identifier, category}: {dataCollection: DataCollection; identifier: string; category: string}): JSX.Element {
    return (
        <Box display="flex" flexWrap="wrap" overflow="auto">
            {dataCollection.parts.map(part => <Part buttonIdentifier={identifier} category={category} part={part} key={`${part.type}-${part.sort}-part`}/>)}
        </Box>
    );
}

function LoadedDataPanel({category}: {category: string}): JSX.Element {
    const [state, setState] = useState<number>(0);
    const collections = useRecoilValue(dataCollectionState({category}));
    const shown = useShownCollections();
    const dataCollection = collections.find(it => shown.some(s => s.id === it.buttonIdentifier));
    useEffect(() => {
        if(dataCollection)
        setState(0);
    }, [dataCollection])
    return <>{dataCollection &&
        <Box zIndex={20000} mr={state === 0 ? 0 : '-360px'} position="relative" right={0} height="100%" overflow="auto" bgcolor="white" flexDirection="column" display="flex" width="400px" p={2} pb={12} pt={1} sx={{borderTop: t => `1px solid ${t.palette.grey[500]}`}}>
            <Box width="100%" display="flex" mb={1} pr={2}>
                <Box zIndex={20001} pr={1} pl={1} onClick={() => setState(prev => prev === 0 ? -1 : 0)} sx={{cursor: 'pointer'}}><FontAwesomeIcon icon={state === 0 ? faChevronRight : faChevronLeft}/></Box>
            </Box>
            <Panel dataCollection={dataCollection.data} identifier={dataCollection.buttonIdentifier} category={category}/>
        </Box>
    }</>;
}

function DataPanel({category}: {category: string}): JSX.Element {
    return <LoadedDataPanel category={category}/>
}

export default memo(DataPanel, isEqual);
