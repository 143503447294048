import {atom, selector, useRecoilState} from "recoil";
import {
  AuthenticationDetails,
  CognitoUser, CognitoUserAttribute, CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {AuthState, UserSettings} from "./auth";

export const local = process.env.REACT_APP_ENV === 'LOCAL';

export function userSettings(claims: { email: string, sub: string, access?: string, apiUrl: string, defaultGroup?: string }): UserSettings {
  return {
    username: claims.email,
    defaultGroup: claims.defaultGroup!,
    apiUrl: local ? 'http://localhost:5001' : claims.apiUrl,
    access: claims.access && JSON.parse(claims.access)
  };
}

const userPool = new CognitoUserPool({ UserPoolId: 'eu-west-2_ankYx9jOe', ClientId: '2lr1ksr3hvlp5rohk3sjm7cdb6' });

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    loading: false,
    local: process.env.REACT_APP_ENV === 'LOCAL',
    updatePassword: false
  }
});

export const jwtToken = selector({
  key: 'jwtTokenState',
  get: ({get}) => get(authState).cognito?.session.getIdToken().getJwtToken()
})

export interface AuthHooks {
  signInCheck: () => Promise<void>;
  signUpUser: (email: string, password: string) => Promise<void>;
  verifyUser: (email: string, code: string) => Promise<void>;
  authenticate: (username: string, password: string, newPassword?: string) => Promise<void>;
  updatePassword: (username: string, oldPassword: string, newPassword: string) => Promise<void>;
  logout: () => void;
}

export function useAuth(): AuthHooks {
  const [auth, setAuth] = useRecoilState(authState);
  
  function user(username: string): CognitoUser { return new CognitoUser({Username: username, Pool: userPool}); }
  
  const signUpUser = (email: string, password: string) =>
    new Promise<void>((resolve, reject) => {
      userPool.signUp(
        email,
        password,
        [new CognitoUserAttribute({ Name: 'email', Value: email })],
        [],
        (error) => {
          if (error) reject(error);
          resolve();
        },
      );
    });
  
  const verifyUser = (email: string, code: string) => new Promise<void>((resolve, reject) => {
    user(email).confirmRegistration(code, false, (error, data) => {
      if(error) reject(error);
      else resolve(data);
    })
  });
  
  const signInCheck = async () => {
    if(!auth.loading) {
      setAuth(old => ({...old, loading: true}));
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        await new Promise<void>((resolve) => cognitoUser!.getSession((error: Error, session: CognitoUserSession | null) => {
          if (!error && session) {
            cognitoUser!.getUserAttributes(() => {
              // const user2 = userSettings(session.getIdToken().decodePayload() as any);
              // const selectedGroup = user2.access.find(it => it.group === user2.defaultGroup)!;
              setAuth(old => ({...old, user: {username: cognitoUser.getUsername(), access: [], apiUrl: '', defaultGroup: ''}, cognito: { session } }));
            });
            resolve();
          }
        }));
      }
      setAuth(old => ({...old, loading: false}));
    }
  };
  
  const completeNewPasswordChallenge = (user2: CognitoUser, newPassword: string) => new Promise<CognitoUserSession>((resolve, reject) => {
    user2.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: resolve,
      onFailure: (e) => {
        setAuth(old => ({...old, errorText: e.message}))
        reject()
      }
    });
  });
  
  return {
    logout: () => {
      userPool.getCurrentUser()?.signOut();
      setAuth(old => ({cognito: undefined, user: undefined, updatePassword: false, loading: false, local: old.local}))
    },
    signUpUser,
    verifyUser,
    signInCheck,
    updatePassword: async (username, oldPassword, newPassword) => {
      await new Promise<void>((resolve, reject) => {
        user(username).changePassword(oldPassword, newPassword, err => {
          if(err) reject(err);
          else resolve();
        })
      });
      setAuth(old => ({...old, updatePassword: false}))
    },
    authenticate: async (username, password, newPassword) => {
      setAuth(old => ({...old, errorText: undefined}));
      const session = await new Promise<CognitoUserSession>((resolve) => {
        const cognitoUser = user(username);
        cognitoUser.authenticateUser(
          new AuthenticationDetails({  Username: username, Password: password, }),
          {
            onSuccess: resolve,
            onFailure: (e) => {
              setAuth(old => ({...old, errorText: e.message}))
            },
            newPasswordRequired: async () => {
              if(newPassword) resolve(await completeNewPasswordChallenge(cognitoUser, newPassword))
              else setAuth(old => ({...old, updatePassword: true}));
            }
          },
        );
      });
      // const settings = userSettings(session.getIdToken().decodePayload() as any);
      // const selectedGroup = settings.access.find(it => it.group === settings.defaultGroup)!;
      setAuth(old => ({...old, user:{username, access: [], apiUrl: '', defaultGroup: ''}, cognito: { session } }));
    }
  }
}
