import { Alert, Box, Button } from '@mui/material';
import React, {PropsWithChildren} from 'react';
import {ErrorBoundary} from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => (
    <Box flex={1} alignItems="center" p={2}>
        <Alert severity="error" sx={{flex: 1, alignItems: 'center', '& .MuiAlert-message': {flex: 1}}}>
            <Box display="flex" alignItems="center">
                <Box flex={1}>{error.message}</Box>
                <Box>
                    <Button variant="outlined" color="error" onClick={resetErrorBoundary}>
                        Retry
                    </Button>
                </Box>
            </Box>
        </Alert>
    </Box>
);



export default function Boundary({children}: PropsWithChildren<any>): JSX.Element {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children}
        </ErrorBoundary>
    );
}
