import { atom, useRecoilState, useRecoilValue } from "recoil";
import {LatLng, LatLngBounds} from "leaflet";
import {Action} from "@hexlabsio/dasha-layers-sdk";
import axios from "axios";
import {ImageOverlay, Popup} from "react-leaflet";
import {CircularProgress} from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { buttonsState, collectionHighlight, shownCollections } from "../../hooks/category/category-state";
import {overlayCollectionState} from "../../hooks/custom-state";
import { mapBounds } from '../../hooks/shared-state';
import { triggerActionIdState } from '../../hooks/use-button-action';
import Properties from "./properties";
import {jwtToken} from "../../hooks/user/auth-state";

const overlayPropsState = atom<{loading: boolean; latitude: number; longitude: number; properties: any}>({key: 'overlayProps', default: { loading: true, latitude: 0, longitude: 0, properties: {}}})

function useOverlayProps(){
    const jwt = useRecoilValue(jwtToken)!;
    const [overlayProps, setOverlayProps] = useRecoilState(overlayPropsState);
    return async (latLng: LatLng, actions: Action[]) => {
        if(overlayProps.latitude !== latLng.lat || overlayProps.longitude !== latLng.lng) {
            setOverlayProps({latitude: latLng.lat, longitude: latLng.lng, loading: true, properties: {}});
            const url = actions[0].id;
            try {
                const result = await axios.get(`https://api.dashaportal.com/layers${url}`, {
                    headers: {Authorization: `Bearer ${jwt}`},
                    params: {latitude: latLng.lat, longitude: latLng.lng}
                });
                if (result.status === 200) {
                    setOverlayProps({
                        latitude: latLng.lat,
                        longitude: latLng.lng,
                        loading: false,
                        properties: (result.data as any).props
                    });
                } else {
                    setOverlayProps({latitude: latLng.lat, longitude: latLng.lng, loading: false, properties: {}});
                }
            }catch(e) {
                setOverlayProps({latitude: latLng.lat, longitude: latLng.lng, loading: false, properties: {}});
            }
        }
    }
}

function debounce(callback: () => void, time: number) {
    let interval: NodeJS.Timeout | null | undefined;
    return () => {
        if(interval) clearTimeout(interval)
        interval = setTimeout(() => {
            interval = null
            callback()
        }, time)
    }
}

function useReloadOverlay({category}: {category: string}) {
    const [buttons, ] = useRecoilState(buttonsState({category}));
    const selected = buttons.filter(it => it.selected);
    const searchable = selected.filter(it => it.actions.some(action => action.id?.startsWith('/layer/airQuality')));
    const [, setTriggerActionIds] = useRecoilState(triggerActionIdState);
    const outerBounds = useRecoilValue(mapBounds);
    const ref = useRef<() => void>();

    const updateOverlay = () => setTriggerActionIds(searchable.map(it => it.identifier));
    const doCallbackWithDebounce = useMemo(() => debounce(() => ref.current?.(), 1000), []);
    useEffect(() => {
        ref.current = updateOverlay
    }, [updateOverlay]);
    useEffect(() => {
        doCallbackWithDebounce();
    }, [outerBounds]);
}

function Overlay({category}: {category: string}): JSX.Element {
    const highlighted = useRecoilValue(collectionHighlight);
    const overlayState = useRecoilValue(overlayCollectionState({category}));
    const overlayProps = useRecoilValue(overlayPropsState);
    const updateProps = useOverlayProps();
    useReloadOverlay({category});
    return <>{overlayState.filter(it => shownCollections().some(s => s.id === it.buttonIdentifier)).map(overlay => {
        const bounds = overlay.overlay[0].overlay.boundingBox;
        return <ImageOverlay
            key={`${overlay.buttonIdentifier}-overlay`}
            opacity={(!highlighted || highlighted[0].identifier === overlay.buttonIdentifier) ? 0.4 : 0.1}
            url={`data:image/png;base64,${overlay.overlay[0].overlay.image}`} bounds={new LatLngBounds([bounds.southWest.latitude, bounds.southWest.longitude], [bounds.northEast.latitude, bounds.northEast.longitude])}
            interactive
            eventHandlers={{click: e => overlay.overlay[0].actions && updateProps(e.latlng, overlay.overlay[0].actions)}}
        ><Popup minWidth={300}>
            {overlayProps.loading ? <CircularProgress/> : <Properties title={overlay.overlay[0].title} category={category} properties={overlayProps.properties} data={(overlayProps as any).data}/>}
        </Popup></ImageOverlay>
    })}</>
}

export default Overlay;
