import isEqual from "lodash.isequal";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue} from "recoil";
import {
    Box,
    Button,
    lighten,
    TextField
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Category, SubCategory} from "@hexlabsio/dasha-categories-sdk";
import {NavLink, useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint, faMap, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useReactToPrint} from "react-to-print";
import Map from "../map";
import {buttonsState, categoryState, shownCollections, useSelectedCategory} from "../../hooks/category/category-state";
import {flyToLocation, mapBounds, searchBounds} from "../../hooks/shared-state";
// import Sidebar from "../sidebar";
import {markerCollectionState, markerPropertiesCollectionState} from "../../hooks/markers/marker-state";
import useIcons from "../../hooks/use-icons";
import {reportsState, useReports} from "../../hooks/reports";
import useButtonAction from "../../hooks/use-button-action";

function Table({category, collection, identifiers}: {category: string; collection: string; identifiers: string[]}): JSX.Element {
    const properties = useRecoilValue(markerPropertiesCollectionState({category, collection, identifiers}));
    const keys = Object.keys(properties?.[0]?.data?.markerProperties ?? {}).filter(it => it !== 'identifier');
    const columns = [{field: 'latitude', headerName: 'Latitude', width: 100},{field: 'longitude', headerName: 'Longitude', width: 100},...keys.map(key => ({field: key, headerName: key, width: 180}))];
    const rows = properties?.map(it => ({...(it.coordinate ?? {}), ...(it.data?.markerProperties ?? {}), id: it.identifier})) ?? [];
    return <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            sx={{borderRadius: '20px', border: '1px solid #e4e4e4', p: 1,
                '& .MuiDataGrid-row:nth-child(2n + 1)': {
                    bgcolor: t => lighten(t.palette.primary.main, 0.95),
                }}}
            pageSize={100}
            rowsPerPageOptions={[20]}
            checkboxSelection={false}
        />
}
const MemoedTable = memo(Table, isEqual);

function Reports(): JSX.Element {
    const {iconFrom} = useIcons();
    const category = useSelectedCategory();
    const history = useHistory();
    const reports = useRecoilValue(reportsState);
    const reportId = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1);
    const selectedReport = reports.find(it => it.identifier === reportId);
    const saveState = useState(false);
    const fullCategory = useRecoilValue(categoryState(category?.identifier ?? ''));
    const bounds = useRecoilValue(mapBounds)
    const selectionArea = useRecoilValue(searchBounds)
    const [, setFlyTo] = useRecoilState(flyToLocation);
    const [markers] = useRecoilState(markerCollectionState({category: category?.identifier ?? ''}));
    const shown = markers.filter(buttonResponse => shownCollections().some(it => it.id === buttonResponse.buttonIdentifier));
    const buttons = useRecoilValue(buttonsState({category: category?.identifier ?? ''}));
    const buttonActions = useButtonAction(category?.identifier ?? '');
    const [reportName, setReportName] = useState(selectedReport?.name ?? 'New Report');
    const reportService = useReports();
    const refreshReports = useRecoilRefresher_UNSTABLE(reportsState);
    const componentRef = useRef();

    async function handleDelete() {
        await reportService.deleteReport(reportId);
        refreshReports();
        history.push(`/map${window.location.search}`);
        saveState[1](s => !s);
    }
    const isNew = reportId === 'new';

    useEffect(() => {
        shownCollections().forEach(({id, ...searchArea}) => {
            const button = buttons.find(it => it.identifier === id);
            if(button) {
                buttonActions.clicked({...button, selected: false}, searchArea)()
            }
        })
    }, [category]);

    const handlePrint = useCallback(useReactToPrint({
            content: () => componentRef.current!,
        }), []);

    const load = useCallback(() => {
        if (selectionArea)
            setFlyTo([{
                latitude: selectionArea.min[0],
                longitude: selectionArea.min[1]
            }, {latitude: selectionArea.max[0], longitude: selectionArea.max[1]}])
        else
            setFlyTo([{latitude: bounds.min[0], longitude: bounds.min[1]}, {
                latitude: bounds.max[0],
                longitude: bounds.max[1]
            }])
    }, []);

    function titleFor(identifier: string, cat: Category | SubCategory | undefined, parents: string[] = []): string | undefined {
        if (!cat) return undefined;
        if (cat.identifier === identifier)
            return [...parents, cat.title].join(' / ')
        return cat.categories.map(it => titleFor(identifier, it, [...parents, cat.title])).find(it => !!it)
    }

    const reportTables = <Box flex={1}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            padding: "1em",
            typography: "h1",
            width: '100%'
        }}>
            <Box>
                <Button variant="outlined" component={NavLink} to={`/map${window.location.search}`}><FontAwesomeIcon style={{width:'16px', marginRight:'.5em'}} icon={faMap}/>Back to Map</Button>
            </Box>
            <TextField sx={{flex:1, mr: 2, ml: 2}} disabled={!isNew} label="Name" value={reportName} onChange={e => setReportName(e.target.value)} />
            <Box/>
            {isNew && <Button variant="outlined" sx={{mr: '1em'}} onClick={() => {
                const report = history.location.search;
                reportService.createReport(reportName, report).then(created => {
                    if (created) {
                        history.push(`/report/${created.identifier}?${created.report}`);
                        saveState[1](s => !s);
                        refreshReports();
                    } else {
                        // TODO ERROR
                    }
                })
            }}><FontAwesomeIcon style={{width:'16px', marginRight:'.5em'}} icon={faSave}/>Save</Button>
            }
            <Box display="flex" alignItems="center">
                <Button variant="outlined" onClick={() => handlePrint()} >
                    <FontAwesomeIcon style={{width:'16px', marginRight:'.5em'}} icon={faPrint}/>
                    Print Report
                </Button>
                {!isNew && <Button variant="outlined" color="secondary" onClick={() => handleDelete()} style={{marginLeft:'1em'}}>
                    <FontAwesomeIcon style={{width:'16px', marginRight:'.5em'}} icon={faTrash}/>
                    Delete
                </Button> }
            </Box>
        </Box>
        <Box pt={2} mb={4} display="flex" >
            <Box pl={2} pr={2} flex={5} sx={{height: '400px', '& .leaflet-container': {borderRadius: '20px'}}}>
                <Map isStatic
                     centre={fullCategory?.centre ? [fullCategory?.centre.latitude, fullCategory?.centre.longitude] : [54, -1]}
                     zoom={fullCategory?.zoom ?? 13} onLoad={load} category={category?.identifier ?? ''}/>
            </Box>
        </Box>
        <Box ref={componentRef} pl={4} pr={4} pb={20} flex={1}>
            {(shown ?? [])
                .map(it => ({title: titleFor(it.buttonIdentifier, fullCategory), markers: it.markers}))
                .map(it => it.markers.map(collection => <Box>
                        <Box display="flex" alignItems="center" mb={2} mt={4}>
                            <Box width={24} height={24}>{collection.icon && iconFrom(collection.icon as any)}</Box>
                            <Box ml={2}>{it.title ?? ''}</Box>
                        </Box>
                        <Box>
                            <React.Suspense fallback={<DataGrid
                                autoHeight
                                rows={[]}
                                columns={[]}
                                loading
                                sx={{borderRadius: '20px', border: '1px solid #e4e4e4', p: 1}}
                                pageSize={100}
                                rowsPerPageOptions={[20]}
                                checkboxSelection={false}
                            />}>
                            <
                                MemoedTable
                                    key={`${collection.identifier}-table-report`}
                                    category={category?.identifier ?? ''}
                                    collection={collection.identifier}
                                    identifiers={collection.member.map(marker => marker.identifier)}
                            />
                            </React.Suspense>
                        </Box>
                    </Box>
                ))}
        </Box>
    </Box>
    return (
        <Box sx={{height: '100%', display: 'flex'}}>
            <Box flex={1} sx={{height: '100%', marginTop: '64px', display: 'flex', flexDirection: 'row', overflowY: 'auto', paddingBottom: '64px'}}>
                {reportTables}
            </Box>
        </Box>
    );
}

export default memo(Reports, isEqual);
