import {DataCollection} from "@hexlabsio/dasha-layers-sdk";
import {Box} from "@mui/material";
import {v4 as uuid} from "uuid";
import React from "react";
import {Panel} from "../data";

function Properties({properties, title, data, category}: {title: string; properties: Record<string, any> | undefined; data: DataCollection | undefined; category: string}): JSX.Element {
    return (
        <Box minWidth="300px" maxHeight="300px"  overflow="auto">
            <Box typography="h2" p={2} textAlign="center">{title}</Box>
            {
                data && <Panel dataCollection={data} identifier={uuid()} category={category}/> ||
                properties && <Box>
                    {Object.keys(properties).map((key, index) => (
                        <Box display="flex" alignItems="center" p={1} sx={{bgcolor: t => index % 2 === 0 ? t.palette.background.paper : t.palette.background.default}}>
                            <Box mr={5} flex={1}>{key}</Box>
                            <Box>{properties[key]}</Box>
                        </Box>
                    ))}
                </Box>
            }
        </Box>
    )
}

export default Properties;
