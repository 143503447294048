import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {Action} from "@hexlabsio/dasha-layers-sdk";
import {
    CollectionResult,
    CollectionSuccessResult,
    mapBounds,
    mapCenter,
    SearchArea,
    searchBounds
} from "./shared-state";
import {useMarkerCollections} from "./markers/marker-state";
import {useLayerCollections} from "./layers/layer-state";
import {useDataCollections} from "./data/data-service";
import {ButtonInfo, buttonsState, useUpdateShownCollections} from "./category/category-state";
import useFiles from "./category/file-service";
import {useCustomCollections} from "./custom-state";

export const triggerActionIdState = atom<string[]>({key: 'triggerActionIdState', default: []});

function useActions(category: string){
    const [latitude, longitude] = useRecoilValue(mapCenter);
    const bounds = useRecoilValue(mapBounds);
    const searchBox = useRecoilValue(searchBounds);
    const markerService = useMarkerCollections(category);
    const layerService = useLayerCollections(category);
    const customService = useCustomCollections(category);
    const dataService = useDataCollections(category);
    const fileService = useFiles(category);
    return async (button: ButtonInfo, buttonActions: Action[], overrideLocation?: SearchArea): Promise<CollectionResult[]> =>
        Promise.all(buttonActions.map(action => {
            if (action.location === 'MARKER') {
                const searchedArea: SearchArea = overrideLocation ?? {
                    lt: (searchBox?.min?.[0] ?? latitude).toString(),
                    ln: (searchBox?.min?.[1] ?? longitude).toString(),
                    r: searchBox ? undefined : '500',
                    xlt: searchBox?.max?.[0]?.toString(),
                    xln: searchBox?.max?.[1]?.toString()
                };
                return markerService.updateCollection(button.identifier, action.id, (action.locationType || searchBox) && searchedArea);
            }
            if (action.location === 'LAYER')
                return layerService.updateCollection(button.identifier, action.id, false);
            if (action.location === 'DATA')
                return dataService.updateCollection(button.identifier, action.id);
            if (action.location === 'FILE')
                return fileService.getFile(action.id);
            if (action.location === 'URL') {
                const searchedArea = overrideLocation ?? {lt: latitude.toString(), ln: longitude.toString(), r: '500', mlt: bounds.max[0].toString(), mln: bounds.min[1].toString(), xlt: bounds.min[0].toString(), xln: bounds.max[1].toString()}
                return customService.updateCollection(button.identifier, action.id, searchedArea);
            }
            return {total: 0}
        }));
}

function useButtonUpdator(category: string) {
    const setButtons = useSetRecoilState(buttonsState({category}));
    return (button: ButtonInfo, fn: (button: ButtonInfo) => ButtonInfo) => {
        setButtons(old => {
            const oldButton = old.find(it => it.identifier === button.identifier)
            if(oldButton) {
                const newButton = fn(oldButton);
                return [...old.filter(it => it.identifier !== button.identifier), newButton];
            }
            return [...old, fn(button)];
        });
    }
}

function useActionTrigger(category: string) {
    const setShown = useUpdateShownCollections();
    const [buttons, setButtons] = useRecoilState(buttonsState({category}));
    const actionService = useActions(category);
    const updateButton = useButtonUpdator(category);
    return async (button: ButtonInfo, actions: Action[], overrideSearchArea?: SearchArea) => {
        updateButton(button, old => ({...old!, loading: true, error: undefined}));
        const totals = await actionService(button, actions, overrideSearchArea);
        if (totals.some(it => (it as any).reason === 'ERROR')) {
            updateButton(button, old => ({...old!, loading: false, error: 'Error', count: 0}));
        } else if (totals.some(it => (it as any).reason === 'NOT_COLLECTION')) {
            updateButton(button, old => ({...old!, loading: false, count: 0}));
        } else {
            updateButton(button, old => ({
                ...old!,
                loading: false,
                count: totals.reduce((sum, next) => sum + ((next as CollectionSuccessResult).total ?? 0), 0),
                selected: true
            }));
            if (actions.some(it => it.location === 'DATA')) {
                setButtons(old => old.map(it => it.selected && it.actions.some(a => a.location === 'DATA') && it.identifier !== button.identifier ? {
                    ...it,
                    selected: false
                } : it))
                setShown(old => {
                    const dataButtons = buttons.filter(it => it.actions.some(action => action.location === 'DATA')).map(it => it.identifier);
                    return [...old.filter(it => it.id !== button.identifier && !dataButtons.includes(it.id)), {id:button.identifier}]
                });
            } else {
                const searchedArea = (totals.find(it => (it as CollectionSuccessResult).searchedArea) as CollectionSuccessResult)?.searchedArea;
                setShown(old => [...old.filter(it => it.id !== button.identifier), {id: button.identifier, ...searchedArea}]);
            }
        }
    }
}

export default function useButtonAction(category: string){
    const setShown = useUpdateShownCollections()
    const [buttons, setButtons] = useRecoilState(buttonsState({category}));
    const updateButton = useButtonUpdator(category);
    const trigger = useActionTrigger(category);
    const clicked = (button: ButtonInfo, overrideSearchArea?: SearchArea) => async (actions?: Action[], parent?: string, repeat?: boolean) => {
        if (button.identifier === 'all') {
            await Promise.all(buttons.filter(it => it.parents[0] === parent).map(async b => {
                const markerActions = (b.actions ?? []).filter(it => it.location === 'MARKER');
                if(markerActions.length > 0) {
                    await trigger(b, markerActions, overrideSearchArea);
                }
            }))
        } else {
            const buttonActions = [...button.actions, ...(actions ?? [])];
            if (parent) updateButton(button,old => ({...old, parents: [parent]}))
            if (buttonActions.length > 0) {
                if (button.selected && !repeat) {
                    updateButton(button,old => ({...old, loading: false, selected: false}));
                    const children = buttons.filter(it => it.parents?.[0] === button.identifier);
                    setButtons(old => [...old.filter(it => !children.some(child => child.identifier === it.identifier)), ...old.filter(it => children.some(child => child.identifier === it.identifier)).map(it => ({
                        ...it,
                        selected: false
                    }))])
                    setShown(old => old.filter(it => !children.some(child => child.identifier === it.id)).filter(it => it.id !== button.identifier));
                } else {
                    await trigger(button, buttonActions, overrideSearchArea);
                }
            }
        }
    }
    return {
        clicked
    }
}