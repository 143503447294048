import {StyleFunction} from "leaflet";
import {useRecoilState, useRecoilValue} from "recoil";
import React, {useState} from "react";
import * as geojson from "geojson";
import {GeoJSON, Popup} from "react-leaflet";
import {Box, CircularProgress} from "@mui/material";
import {layerCollectionState} from "../../hooks/layers/layer-state";
import {collectionHighlight, shownCollections} from "../../hooks/category/category-state";
import Properties from "./properties";
import {FeaturePopup} from "./popup";

function featureStyle(isHighlighted: boolean): StyleFunction<any> {
    return feature => {
        const fillOpacity = Math.min(feature?.properties?.style?.fillOpacity ?? (feature?.properties?.style?.fillColor ? 0.4 : 0), 0.4);
        const dashed = !!feature?.properties?.style?.icons
        const strokeOpacity = dashed ? 1 : (feature?.properties?.style?.strokeOpacity ?? (feature?.properties?.style?.strokeColor ? 0.6 : 0));
        const fill =  fillOpacity !== 0 || feature?.properties?.style?.fillColor;
        const stroke = strokeOpacity !== 0;
        const weight = feature?.properties.style.weight ?? feature?.properties.style.strokeWeight;
        return {
            dashArray: dashed ? '3, 5' : undefined,
            ...feature?.properties.style,
            color: feature?.properties.style.strokeColor,
            fillOpacity: fillOpacity * (isHighlighted ? 1 : 0.2),
            opacity: strokeOpacity * (isHighlighted ? 1 : 0.2),
            fill,
            stroke,
            ...((weight || weight === 0) ? {weight} : {} )
        }
    }
}

function Layers({category}: {category: string}): JSX.Element {
    const highlighted = useRecoilValue(collectionHighlight);
    const [layers] = useRecoilState(layerCollectionState({category}));
    const [openFeature, setOpenFeature] = useState<geojson.Feature<geojson.GeometryObject,  any> | undefined>(undefined);
    return <>{
        layers.flatMap(buttonResponse => buttonResponse.permanent || shownCollections().some(it => it.id === buttonResponse.buttonIdentifier) ? buttonResponse.layers.map(collection => {
            const isHighlighted = buttonResponse.permanent || (!highlighted || highlighted[1] === buttonResponse.buttonIdentifier);
            return (
                <GeoJSON key={collection.identifier} data={collection.features as any} style={featureStyle(isHighlighted)} onEachFeature={(feature, layer) => layer.on({click: e => { setOpenFeature(e.target.feature)}})}>
                    <Popup minWidth={300}>
                        <React.Suspense fallback={<Box height="300px" width="300px" textAlign="center" display="flex" alignItems="center" justifyContent="center"><CircularProgress/></Box>}>
                            {
                                ((openFeature?.properties as any)?.properties  || (openFeature?.properties as any)?.data) ?
                                    <Properties title={openFeature?.properties.title ?? ''} category={category} properties={(openFeature?.properties as any)?.properties} data={(openFeature?.properties as any)?.data}/> :
                                    <FeaturePopup category={category} collection={collection.identifier} feature={openFeature}/>
                            }
                        </React.Suspense>
                    </Popup>
                </GeoJSON>
            )
        }) : [])
    }</>
}

export default Layers;
