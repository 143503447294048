import { CarbonRequest, CarbonResponse } from '@hexlabsio/dasha-categories-sdk';
import { atomFamily, selectorFamily, useRecoilValueLoadable } from 'recoil';
import { categorySdk } from '../sdks';
import { jwtToken } from '../user/auth-state';


const calculatorFieldsSelector = selectorFamily<CarbonResponse | undefined, {
  percentageOfRAPInAsphaltMix: number;
  roadSchema: string;
  address: string;
  length: number;
  width: number;
  depth: number;
  tonnageOfPlanning: number;
  numberOfPavingMachine: number;
  distanceFromHotMix: number;
  trackCapacity: number;
  perOfWarmMix: number;
  wastesurplusAsphalt: number;
}>({
  key: 'calculatorFieldsSelector',
  get: request => async ({get}) => {
    const jwt = get(jwtToken)
    if(jwt) {
      const result = await categorySdk(jwt).getCarbon(request);
      if (result.statusCode === 200) {
        return result.result;
      }
    }
    return undefined;
  }
});

const calculatorFieldsState = atomFamily({key: 'calculatorFieldsState', default: calculatorFieldsSelector});

export default function useCalculatorFields(request: CarbonRequest) {
  return useRecoilValueLoadable(calculatorFieldsState(request));
}