import isEqual from "lodash.isequal";
import * as React from 'react';
import {memo, useEffect} from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {useRecoilState} from "recoil";
import {authState, useAuth} from "../../hooks/user/auth-state";

function ProtectedRoute(props: RouteProps): JSX.Element{
  const [user] = useRecoilState(authState);
  const auth = useAuth();
  useEffect(() => {
    if(!user.user) {
      auth.signInCheck()
    }
  }, [])
  return user.user && user.cognito?.session?.isValid() ? <Route {...props} /> : <Redirect to={`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`} />;
};

export default memo(ProtectedRoute, isEqual);
