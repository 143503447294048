import React, {memo, useCallback} from 'react';
import isEqual from "lodash.isequal";
import {Box, Button, CircularProgress, Menu, MenuItem} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faChartBar, faInfoCircle, faSignOutAlt, faSync, faPlus} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from "react-router-dom";
import dashaLogo from '../../dasha-logo.svg';
import fitzpatrickLogo from '../../fitzpatrick-logo.svg';
import {jwtToken, useAuth} from "../../hooks/user/auth-state";
import {
    categoryState,
    userCategories,
    useResetState, useSelectCategory,
    useSelectedCategory
} from "../../hooks/category/category-state";
import {reportsState} from "../../hooks/reports";
import {flyToLocation} from "../../hooks/shared-state";

function HamburgerMenu({showReset}: {showReset: boolean}): JSX.Element {
    const auth = useAuth();
    const resetState = useResetState();
    const selectedCategory = useSelectedCategory();
    const category = useRecoilValue(categoryState(selectedCategory?.identifier ?? ''));
    const setFlyTo = useSetRecoilState(flyToLocation);
    const flyToReset = useCallback(() => {
        if(category)
        setFlyTo([category.centre.latitude, category.centre.longitude, category.zoom])
    }, [category])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = !!anchorEl;
    function close() { setAnchorEl(null) }
    return (
        <Box ml={4} mr={2}>
            <
                Button
                sx={{color: 'white', fontSize: '1.4em'}}
                id="nav-hamburger"
                aria-controls="nav-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={event => setAnchorEl(event.currentTarget)}
            ><FontAwesomeIcon icon={faBars}/></Button>
            <Menu
                id="nav-menu"
                anchorEl={anchorEl}
                sx={{zIndex: 7000}}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'nav-hamburger',
                }}
            >
                {showReset && <MenuItem onClick={() => {close(); resetState(); flyToReset()}}><Box mr={2}><FontAwesomeIcon icon={faSync}/></Box>Reset View</MenuItem>}
                <MenuItem disabled onClick={() => close()}><Box mr={2}><FontAwesomeIcon icon={faInfoCircle}/></Box>About</MenuItem>
                <MenuItem onClick={() => { close(); auth.logout()} }><Box mr={2}><FontAwesomeIcon icon={faSignOutAlt}/></Box>Logout</MenuItem>
            </Menu>
        </Box>
    )
}

function ReportMenu(): JSX.Element {
    const reports = useRecoilValue(reportsState);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = !!anchorEl;
    function close() { setAnchorEl(null) }
    const goto = useCallback((uri) => {
        close();
        history.push(`${uri}`);
    }, [history]);
    return (
        <Box ml={2} mr={2}>
            <
                Button
                sx={{color: 'white', fontSize: '1.4em'}}
                id="nav-reports"
                title="Reports"
                aria-controls="nav-report-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={event => setAnchorEl(event.currentTarget)}
            ><FontAwesomeIcon icon={faChartBar}/></Button>
            <Menu
                id="nav-report-menu"
                anchorEl={anchorEl}
                sx={{zIndex: 7000}}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'nav-reports',
                }}
            >
                <MenuItem onClick={() => {goto(`/report/new${window.location.search}`)}}>
                    <Box mr={2}><FontAwesomeIcon icon={faPlus}/></Box>Create Report</MenuItem>
                {
                    reports.map(report => <MenuItem onClick={() => {goto(`/report/${report.identifier}${report.report}`)}}>
                        <Box mr={2}>
                            <FontAwesomeIcon icon={faChartBar}/></Box>Report - {report.name}
                    </MenuItem>)
                }
            </Menu>
        </Box>
    )
}

function RegionMenu({disabled}: {disabled: boolean}): JSX.Element {
    const category = useSelectedCategory();
    const categoryList = useRecoilValue(userCategories);
    const selectCategory = useSelectCategory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = !!anchorEl;
    function close() { setAnchorEl(null) }
    return (
        <Box ml={2} mr={2}>
            <
                Button
                disabled={disabled}
                sx={{'&.Mui-disabled': { color: 'white'}, color: 'white', fontSize: '1.4em', typography: 'subtitle1'}}
                id="nav-regions"
                title="Region"
                aria-controls="nav-regions-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={event => setAnchorEl(event.currentTarget)}
            >{category ? category.title : 'No Regions Available'}</Button>
            <Menu
                id="nav-regions-menu"
                anchorEl={anchorEl}
                sx={{zIndex: 7000}}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'nav-regions',
                }}
            >
                {categoryList.filter(it => it.identifier !== category?.identifier).map(cat => <MenuItem key={`${cat.identifier}-region-menu`} onClick={() => {close(); selectCategory(cat.identifier)}}>{cat.title}</MenuItem>)}
            </Menu>
        </Box>
    )
}

function NavBar({showReports}: {showReports: boolean}): JSX.Element {
    const jwt = useRecoilValue(jwtToken);
    return (
        <Box display="flex" alignItems="center" position="fixed" top={0} zIndex={6000} width="100%" sx={{height: t => t.spacing(8), bgcolor: t => t.palette.primary.main, color: 'white'}}>
            <Box flex={1}>{jwt ? <Box display="flex">
                <HamburgerMenu showReset={showReports}/>
                    {showReports && <React.Suspense fallback={<CircularProgress/>}><ReportMenu /></React.Suspense> }
                    <RegionMenu disabled={!showReports}/>
            </Box>: <></>}</Box>
            <Box ml={20}><img height="64px" alt="DASHA" src={dashaLogo}/></Box>
            <Box flex={1}/>
            <Box mr={2} display="flex" alignItems="center"><img height="32px" alt="Fitzpatrick Advisory" src={fitzpatrickLogo}/></Box>
            <Box mr={4}>Fitzpatrick Advisory</Box>
        </Box>
    )
}

export default memo(NavBar, isEqual);
