// @ts-ignore

import _maplibreGL from '@maplibre/maplibre-gl-leaflet';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    createTileLayerComponent, LayerProps, updateGridLayer
} from '@react-leaflet/core';
import L, { TileLayer as LeafletTileLayer, TileLayerOptions } from 'leaflet';

_maplibreGL.preventRemovalOfUnusedImport = true;

export interface VectorTileLayerProps extends TileLayerOptions, LayerProps {
    styleUrl: string
}

const VectorTileLayer = createTileLayerComponent<
    LeafletTileLayer,
    VectorTileLayerProps
    >(({ styleUrl, ...options }, context) => ({
        // eslint-disable-next-line new-cap
        instance: new (L as any).maplibreGL({ style: styleUrl, ...options, transformRequest: (url: string) => {
                if(! /[?&]key=/.test(url) ) return {url:`${url}?key=j3fSlegsFIcMaHGuVGEqM78RqedkGgT7&srs=3857`}
                return {url:`${url}&srs=3857`}
            }}),
        context,
    }), updateGridLayer);


export default VectorTileLayer;