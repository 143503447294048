import {atomFamily, useRecoilValue, useSetRecoilState} from "recoil";
import {DataCollection} from "@hexlabsio/dasha-layers-sdk";
import {layersSdk} from "../sdks";
import {jwtToken} from "../user/auth-state";
import {CollectionResult} from "../shared-state";

export type DataButtonResponse = {buttonIdentifier: string; data: DataCollection}

export const dataCollectionState = atomFamily<DataButtonResponse[], {category: string}>({key: 'DataCollections', default: () => []});

export function useDataCollections(category: string) {
    const jwt = useRecoilValue(jwtToken)!;
    const setItems = useSetRecoilState(dataCollectionState({category}));
    return {
        async updateCollection(collection: string, layerCollection: string): Promise<CollectionResult> {
            try {
                const data = await layersSdk(jwt).getDataCollection({ category, collection: layerCollection });
                if (data.statusCode === 200) {
                    setItems(old => {
                        const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, data: {parts: []}};
                        return [...old.filter(it => it.buttonIdentifier !== collection), {...update, data: data.result}];
                    });
                    return {total: 0}
                }
            } catch(e) {
                return {reason: 'ERROR'}
            }
            return {total: 0}
        }
    }
}