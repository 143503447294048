import isEqual from "lodash.isequal";
import React, {memo, PropsWithChildren, useRef} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Box} from "@mui/material";
import {Map as LeafletMap} from 'leaflet';
import Dasha from "../dasha";
import PrivateRoute from "./private";
import Login from "../login";
import NavBar from "../navbar";
import Reports from "../dasha/reports";

function Nav({children, showReports}: PropsWithChildren<{showReports: boolean}>): JSX.Element {
    return (
        <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
            <NavBar showReports={showReports}/>
            <Box flex={1} height="100%">
                {children}
            </Box>
        </Box>
    )
}

function Routes(): JSX.Element {
    const mapRef = useRef<LeafletMap | undefined> (undefined);

  return (
    <Switch>
        <PrivateRoute path="/map" render={() => <Nav showReports>
            <Dasha onLoad={map => {mapRef.current = map}}/>
        </Nav> } exact />
        <PrivateRoute path="/report/:reportId" render={() => <Nav showReports={false}>
                <Reports />
        </Nav> } exact />
        <Route path="/login" render={() => <Nav showReports={false}><Login /></Nav>} />
      <Route path="/" exact render={() => <Redirect to={`/map${window.location.search}`}/>} />
    </Switch>
  );
}

export default memo(Routes, isEqual)
