import React, {memo, useEffect, useState} from 'react';
import {
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    fas,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from "lodash.isequal";
// eslint-disable-next-line import/no-extraneous-dependencies
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';

import {
    Box, Button,
    CircularProgress,
    lighten,
    ToggleButton, Typography
} from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {ImageDetail, SubCategory} from "@hexlabsio/dasha-categories-sdk";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    buttonsState,
    buttonState, categoryState,
    collectionHighlight,
    useParentSelected
} from '../../hooks/category/category-state';
import ErrorBoundary from "../../components/error-boundary";
import useIcons from "../../hooks/use-icons";
import useButtonAction, {triggerActionIdState} from "../../hooks/use-button-action";
import {useSpecialMarker} from "../../hooks/markers/marker-state";
import { flyToLocation, resetter, searchRadius, showTraffic } from "../../hooks/shared-state";

library.add(fas);


function SearchMenu({category}: {category: string;}): JSX.Element {
    const [value, setValue] = useState<any>(null);
    const specialMarker = useSpecialMarker(category, 'location');
    const flyTo = useSetRecoilState(flyToLocation)
    const resetterCount = useRecoilValue(resetter);
    useEffect(() => {
        setValue(null);
    }, [resetterCount])
    useEffect(() => {
        if(value) {
            specialMarker.remove()
            geocodeByPlaceId(value.value.place_id)
              .then((place) => {
                  const latlng = place[0].geometry.location;
                  flyTo([{latitude: latlng.lat(), longitude: latlng.lng()}]);
                  specialMarker.set({identifier: 'location', member: [{title: place[0].formatted_address, identifier: 'location', coordinate: {latitude: latlng.lat(), longitude: latlng.lng()}}]} as any, {});
              })
              .catch(e => {
                  console.log(e);
              })
        }
    }, [value]);
    return (
        <Box m={1} my={2}>
            <GooglePlacesAutocomplete
              selectProps={{
                value,
                onChange: (v: any) => setValue(v),
            }} autocompletionRequest={{ componentRestrictions: { country: ['GB']}}} apiKey="AIzaSyBddeAtqgROzJwiRstnDlBgFZJHkbA2C90" />
        </Box>
    )
}

function RegionImage({image}: {image: ImageDetail}): JSX.Element {
    const {iconFrom} = useIcons();
    if(image.type === 'DATA') {
        return (
            <Box display="flex" p={2}>
                {iconFrom(image)}
            </Box>
        );
    }
    return <></>;
}

function Title({category, identifier, title, onClick, opensDown, icon, open, opens, active}: {category: string; identifier: string; title: string; open: boolean; opensDown: boolean; active: boolean; icon: ImageDetail | undefined; onClick: () => void; opens: boolean}): JSX.Element {
    const {iconFrom} = useIcons();
    const setHighlight = useSetRecoilState(collectionHighlight);
    const setSearchRadius = useSetRecoilState(searchRadius);
    const button = useRecoilValue(buttonState({category, identifier}));
    const parentsSelected = useParentSelected(category, identifier);
    const {clicked} = useButtonAction(category);
    const [triggerActionIds, setTriggerActionIds] = useRecoilState(triggerActionIdState);
    useEffect(() => {
        if(button.selected && !button.loading && triggerActionIds.includes(button.identifier)) {
            clicked(button)(undefined, undefined, true).then(() => setTriggerActionIds(old => old.filter(it => it !== button.identifier)));
        }
    }, [triggerActionIds])
    const openIcon = opensDown ? faChevronUp : faChevronLeft;
    const closedIcon = opensDown ? faChevronDown : faChevronRight;
    return (
        <Box
            onMouseEnter={() => {
                if(active && button.selected) setHighlight([button, identifier]);
                if(!button.selected)
                    setSearchRadius(button.actions.reduce((max, next) => Math.max(next.locationType?.type === 'RADIAL' ? next.locationType.radius ?? 500 : 0, max), 0));
            }}
            onMouseLeave={() => {
                setHighlight(old => old?.[1] === identifier ? undefined : old);
                setSearchRadius(0);
            }}
            onClick={() => {onClick(); clicked(button)()}}
            p={1}
            display="flex"
            alignItems="center"
            sx={{
                cursor: 'pointer',
                borderLeftStyle: 'solid',
                borderLeftColor: t => t.palette.primary.main, borderLeftWidth: parentsSelected ? '5px' : '0',
                bgcolor: t => button.selected ? t.palette.background.default : t.palette.background.paper, opacity: active ? 1 : 0.4,
                color: t => button.error ? t.palette.error.main : 'black',
                zIndex: 10000,
                '&:hover': {
                    bgcolor: t => lighten(t.palette.primary.main, 0.9)
                }
            }}
        >
            <Box display="flex" mr={2} ml={2} sx={{'img': {width: '20px', height: '20px'}}}>{button.loading ? <CircularProgress size={20}/> : button.error && <FontAwesomeIcon icon={faExclamationTriangle}/> || (icon && iconFrom(icon))}</Box>
            <Box flex={1} typography="body1" sx={{fontWeight: button.selected ? 500 : 300}}>{title}</Box>
            {opens && <FontAwesomeIcon icon={open ? openIcon : closedIcon}/> }
            {button.selected && <Box pl={1} pr={1} mr={1} borderRadius='50vh' typography="body1" sx={{bgcolor: t => t.palette.grey[300]}}>{button.count ? button.count : ''}</Box> }
        </Box>
    )
}

function SearchAllButton({ root, category }: { root: string; category: SubCategory }) {
    const button = useRecoilValue(buttonState({category: root, identifier: 'all'}));
    const {clicked} = useButtonAction(button.category);
    return (
      <Button onClick={e => {
            e.stopPropagation();
            clicked(button)(undefined, category.identifier);
        }} variant="outlined" sx={{borderRadius: '50vh', pl: '.4em', pr: '.4em', pt: '0', pb: '0', mr: '1.5em', ml: '1.5em', mt: '1em', mb: '1em'}}>
          Search All Assets
      </Button>
    );
}

function CategoryRoot({root, category, childClicked, openChildren}: {root: string; category: SubCategory, openChildren: string[]; childClicked: (child: SubCategory) => void}): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const parentsSelected = useParentSelected(root, category.identifier);
    const hasChildren = category.categories.length > 0;
    const hasActions = (category.actions?.length ?? 0) > 0;
    const resetterCount = useRecoilValue(resetter);

    useEffect(() => {
        setOpen(false);
    }, [resetterCount])
    return (
        <Box key={`${category.identifier}-root`} >
            <Box onClick={() => {setOpen(prev => !prev);}} p={2} display="flex" alignItems="center" sx={{cursor: 'pointer', opacity: hasChildren || hasActions ? 1 : 0.2, borderLeftStyle: 'solid', borderLeftColor: t => t.palette.primary.main, borderLeftWidth: parentsSelected ? '5px' : '0'}}>
                <Box flex={1} display="flex" alignItems="center">
                    <Box typography="h2">{category.title}</Box>
                </Box>
                {hasChildren && <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}/> }
            </Box>
            {open && category.categories.map(child => (
                <Title identifier={child.identifier} category={root} key={child.identifier} active opens={child.categories.length > 0} icon={child.icon} opensDown={false} open={openChildren.includes(child.identifier)} title={child.title} onClick={() => childClicked(child)}/>
            ))}
        </Box>
    )
}

function ActionableCategoryRoot({category, childClicked, openChildren, root}: {root: string; category: SubCategory, openChildren: string[]; childClicked: (child: SubCategory) => void}): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const hasChildren = category.categories.length > 0;
    const hasActions = category.actions?.length !== 0;
    return (
        <Box key={`${category.identifier}-root`} sx={{mb: (open && hasChildren) ? 1 : 0}} >
            <Title identifier={category.identifier} category={root} active={hasActions || hasChildren} title={category.title} open={open} opensDown onClick={() => setOpen(prev => !prev)} opens={hasChildren} icon={category.icon}/>
            {open && category.categories.map(child => (
                <Box ml={2} key={child.identifier}>
                    <ActionableCategoryRoot root={root} category={child} openChildren={openChildren} childClicked={childClicked}/>
                </Box>
            ))}
        </Box>
    )
}

function valuesFor(layer: string) {
    if(layer === 'AQI') {
        return [
          '0-3',
          '4-6',
          '7-9',
          '10'
        ]
    }
    if(layer === 'PM25') {
        return ['0-11.9',
        '12-23.9',
        '24-69.9',
        '70+']
    }
    return [
      '0-24.9',
      '25-54.9',
      '55-149.9',
      '150+'
    ]
}

function titleFor(layer: string) {
    if(layer === 'AQI') return 'Air Quality Index';
    if(layer === 'PM25') return 'Particulate Matter'
    return 'Nitrogen Dioxide';
}

function Colorscale({layer}: {layer: string}) {
    const layerName = layer.substring(layer.lastIndexOf('/') + 1);
    const values = valuesFor(layerName);
    const title = titleFor(layerName);
    return (
      <Box mt={2} sx={{
          '& .band-identifier': {
              marginRight: '10px',
              height: '16px',
              width: '16px',
              borderRadius: '50%',
              display: 'inline-block',
              verticalAlign: 'middle'
          },
          '& .band-description': {
              flex: 1,
              textAlign: 'right',
          },
          '& .list': {
              listStyleType: 'none',
              marginBlockStart: 0,
              paddingInlineStart: 0,
              padding: '2em',
              paddingTop: '1em'
          },
          '& .band': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              marginBottom: '1em',
              fontWeight: 'lighter'
          }
      }}>
          <Box textAlign="center"><Typography variant="h2">{title} Scale</Typography></Box>
          <div className="sg-container">
              <ul className="list">
                  <li className="band"><span className="band-identifier"
                                             style={{backgroundColor: "rgb(156, 255, 156)"}} />{values[0]}<span
                    className="band-description">Low</span></li>
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(49, 255, 0)"}} />{values[1]}<span */}
                  {/*  className="band-description">Very low for the UK</span></li> */}
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(49, 207, 0)"}} />{values[2]}<span */}
                  {/*  className="band-description">Relatively low for the UK</span></li> */}
                  <li className="band"><span className="band-identifier"
                                             style={{backgroundColor: "rgb(255, 255, 0)"}} />{values[1]}<span
                    className="band-description">Moderate</span></li>
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(255, 207, 0)"}} />{values[4]}<span */}
                  {/*  className="band-description">Moderate for the UK</span></li> */}
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(255, 154, 0)"}} />{values[5]}<span */}
                  {/*  className="band-description">Moderately high for the UK</span></li> */}
                  <li className="band"><span className="band-identifier"
                                             style={{backgroundColor: "rgb(255, 92, 1)"}} />{values[2]}<span
                    className="band-description">High</span></li>
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(255, 0, 0)"}} />{values[7]}<span */}
                  {/*  className="band-description">High for the UK</span></li> */}
                  {/* <li className="band"><span className="band-identifier" */}
                  {/*                           style={{backgroundColor: "rgb(153, 0, 0)"}} />{values[8]}<span */}
                  {/*  className="band-description">Exceptionally high for the UK</span></li> */}
                  <li className="band"><span className="band-identifier"
                                             style={{backgroundColor: "rgb(100, 0, 43)"}} />{values[3]}<span
                    className="band-description">Very High</span></li>
              </ul>
          </div>
      </Box>
    )
}


function SidebarExtra({category, close, root}: {root: string; category: SubCategory | undefined, close: () => void}): JSX.Element {
    const [buttons, ] = useRecoilState(buttonsState({category: root}));
    const colorScales = buttons.filter(it => it.selected)
      .flatMap(it => it.actions?.filter(action => action.id?.startsWith('/layer/airQuality')) ?? []);
    const colorPick = colorScales.length > 0 ? colorScales[colorScales.length - 1] : undefined;

    return (
        <Box display="flex" flexDirection="column" overflow="auto" sx={{
            zIndex: t => t.zIndex.drawer - 1,
            width: t => t.spacing(35),
            bgcolor: t => t.palette.background.paper,
            position: 'fixed',
            top: t => t.spacing(8),
            left: t => category ? t.spacing(35) : 0,
            transition: 'left .5s',
            height: '100%',
            pb: t => t.spacing(8)
        }}>
            <Box onClick={close} p={2} display="flex" alignItems="center" sx={{cursor: 'pointer'}}>
                <Box><FontAwesomeIcon icon={faChevronLeft}/></Box>
                <Box flex={1} typography="h2" textAlign="center">{category?.title}</Box>
            </Box>
            {
                category?.categories.map(child => (
                    <ActionableCategoryRoot key={child.identifier} root={root} category={child} openChildren={[]} childClicked={() => {}}/>
                ))
            }
            { colorPick && <Colorscale layer={colorPick.id} /> }
        </Box>
    );
}

function Sidebar({category}: {category: string;}): JSX.Element {
    const [open, setOpen] = useState<SubCategory | undefined>(undefined);
    const [shouldShowTraffic, setShowTraffic] = useRecoilState(showTraffic);
    const categoryRoot = useRecoilValue(categoryState(category));
    const resetterCount = useRecoilValue(resetter);

    useEffect(() => {
        setOpen(undefined);
        setShowTraffic(false);
    }, [resetterCount])

    const dataCategory = categoryRoot?.categories.find(it => it.title === 'Data');

    return <Box display="flex" sx={{zIndex: t => t.zIndex.drawer, top: t => t.spacing(8), position: 'fixed', height: '100%', pb: 8}}>
        <Box display="flex" pb={8} overflow="auto" flexDirection="column" sx={{zIndex: t => t.zIndex.drawer, width: t => t.spacing(35),  bgcolor: t => t.palette.background.default}}>
            {categoryRoot?.regionImage && <RegionImage image={categoryRoot.regionImage}/> }
                <SearchMenu category={category}/>
            <Box display="flex" >
                <ToggleButton value="traffic" color="primary" selected={shouldShowTraffic}  onClick={() => setShowTraffic(x => !x)} sx={{width: '100%', borderRadius: '50vh', pt: 0, pb: 0, mt: 2, ml: 2, mr: 2, textTransform: 'none'}}>Traffic</ToggleButton>
                </Box>
            { dataCategory && <SearchAllButton category={dataCategory} root={category} /> }
            {categoryRoot?.categories.map(child => <CategoryRoot key={child.identifier} root={category} category={child} openChildren={open ? [open.identifier] : []} childClicked={c => setOpen(p => p === c ? undefined : c)}/>)}
        </Box>
        { open && <SidebarExtra root={category} category={open} close={() => setOpen(undefined)}/> }
    </Box>
}

function Loading(): JSX.Element {
    return (
        <Box display="flex" sx={{zIndex: t => t.zIndex.drawer, top: t => t.spacing(8), position: 'fixed', height: '100%'}}>
            <Box sx={{
                zIndex: t => t.zIndex.drawer,
                width: t => t.spacing(35),
                bgcolor: t => t.palette.background.default,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box typography="h2" mr={2}>Loading</Box> <CircularProgress/>
            </Box>
        </Box>
    )
}

function Wrapper({category}: {category: string;}): JSX.Element {
    return <React.Suspense fallback={<Loading/>}>
        <ErrorBoundary>
            <Sidebar category={category}/>
        </ErrorBoundary>
    </React.Suspense>
}

export default memo(Wrapper, isEqual);
