import {atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {LayerCollection, FeatureData} from "@hexlabsio/dasha-layers-sdk";
import {layersSdk} from "../sdks";
import {jwtToken} from "../user/auth-state";
import {CollectionResult, findFeatureBounds, flyToLocation} from "../shared-state";

export type LayerButtonResponse = {buttonIdentifier: string; layers: LayerCollection[]; permanent: boolean}

export const layerCollectionState = atomFamily<LayerButtonResponse[], {category: string}>({key: 'Layers', default: () => []});

export function useLayerCollections(category: string) {
  const jwt = useRecoilValue(jwtToken)!;
  const [, setItems] = useRecoilState(layerCollectionState({category}));
  const flyTo = useSetRecoilState(flyToLocation);
  return {
    async updateCollection(collection: string, layerCollection: string, permanent: boolean): Promise<CollectionResult> {
      try {
        const layers = await layersSdk(jwt).getLayerCollection({ category, collection: layerCollection });
        if (layers.statusCode === 200) {
          setItems(old => {
            const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, layers: [], permanent};
            return [...old.filter(it => it.buttonIdentifier !== collection), {...update, permanent, layers: [layers.result]}]
          });
          if(!permanent)  flyTo(findFeatureBounds(layers.result.features.flatMap(it => it.features)));
          const sum = layers.result.features.flatMap(it => it.features.length).reduce((total, count) => count + total, 0);
          return {total: sum, searchedArea: {}}
        }
      } catch(e) {
        return {reason: 'ERROR'}
      }
      return {total: 0, searchedArea: {}}
    }
  }
}

const layerPropertiesSelector = selectorFamily<FeatureData | undefined, {category: string; collection: string; identifier: string}>({
  key: 'layerPropertiesSelector',
  get: ({category, collection, identifier}) => async ({get}) => {
    const jwt = get(jwtToken)!;
    const data = await layersSdk(jwt).getFeature({category, collection, identifier});
    if(data.statusCode === 200) {
      return data.result
    }
    return undefined;
  }})
export const layerPropertiesState = atomFamily({key: 'layerPropertiesState', default: layerPropertiesSelector});