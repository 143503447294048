import {Box, Button, CircularProgress, Typography} from "@mui/material";
import isEqual from "lodash.isequal";
import React, {memo} from "react";
import {UnpackNestedValue, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useRecoilState} from "recoil";
import Input from "../../components/input";
import {authState, useAuth} from "../../hooks/user/auth-state";
import mapBackground from './map-background.png';
import dashaLogo from '../../dasha-logo.svg';

interface LoginFormData {
  username: string;
  password: string;
  updatePassword: string;
}

function Login(): JSX.Element {
  const [user] = useRecoilState(authState);
  const { register, handleSubmit } = useForm<LoginFormData>();
  const auth = useAuth();
  const history = useHistory();
  if(user.user) {
    const redirect = new URLSearchParams(history.location.search).get('redirect');
    if(redirect) history.push(redirect)
  }
  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" sx={{bgcolor: t => t.palette.background.default}}>
      <Box flex={1}/>
      <Box sx={{color: t => t.palette.error.main}}>{user.errorText}</Box>
        <Box mt={4} display="flex"  sx={{borderRadius: '20px', border: t => `1px solid ${t.palette.divider}`}}>
        <Box>
          <Box sx={{position: 'absolute', width: '250px', marginLeft: '64px', marginTop: '40px', zIndex: 10, display:'flex', flexDirection:'column', alignItems: 'center'}}>
            <img src={dashaLogo} alt="DASHA"/>
            <Typography>Smarter Cities Portal</Typography>
          </Box>
          <img src={mapBackground} alt="" style={{borderRadius: '20px', marginBottom: '-6px', height: '300px'}}/>
        </Box>
          <Box bgcolor="white" p={2} sx={{borderTopRightRadius: '20px', borderBottomRightRadius: '20px', ml: '-214px', width: '400px', zIndex:10 }}>
          <form onSubmit={handleSubmit((data: UnpackNestedValue<LoginFormData>) => auth.authenticate(data.username, data.password, data.updatePassword))}>
            <Input textProps={{...register('username')}} label="Username" placeholder="Username" />
            <Input textProps={{...register('password'), type: 'password'}} label="Password" placeholder="Password" />
            {user.updatePassword && <Input textProps={{...register('updatePassword'), type: 'password'}} label="Update Password" placeholder="Update Password" />}
            <Button type="submit" variant="outlined" sx={{width: '100%', mt:2, height: t=> t.spacing(6)}}>{ user.loading && <CircularProgress size={20} sx={{mr:2}} />}LOGIN</Button>
          </form>
          </Box>
        </Box>
      <Typography variant="body1" fontSize="14px" sx={{color: t => t.palette.grey[600]}}>&copy; Fitzpatrick Advisory</Typography>
      <Box flex={2}/>
    </Box>
  )
}

export default memo(Login, isEqual);
