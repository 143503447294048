import {atom, selector, useRecoilValue} from "recoil";
import {Report} from "@hexlabsio/dasha-categories-sdk";
import {jwtToken} from "./user/auth-state";
import {categorySdk} from "./sdks";


const reportsSelector = selector<Report[]>({key: 'reportsSelector', get: async ({get}) => {
    const jwt = get(jwtToken)!;
    const response = await categorySdk(jwt).getReports();
    if(response.statusCode === 200) {
        return response.result.member;
    }
    return [];
} });

export function useReports() {
    const jwt = useRecoilValue(jwtToken)!;
    const sdk = categorySdk(jwt);
    async function createReport(name: string, report: string): Promise<Report | undefined> {
        const response = await sdk.createReport({report, name});
        if(response.statusCode === 201){
            return response.result;
        }
        return undefined
    }

    async function deleteReport(reportId: string): Promise<void> {
        await sdk.deleteReport({reportId});
    }
    return {
        createReport,
        deleteReport
    }
}

export const reportsState = atom({key: 'reportsState', default: reportsSelector });

export const showReportsState = atom({key: 'showReportsState', default: false});
