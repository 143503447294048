import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import { Box, CircularProgress, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import Routes from "./features/routes";

const spacing = 8;
const theme = createTheme({
  spacing,
  palette: {
    primary: {
      main: '#51888b'
    },
    divider: '#dddddd',
    background: {
      default: '#f5f5f5'
    }
  },
  typography: {
    fontWeightLight: 300,
    h1: {
      fontSize: '24px',
      fontWeight: 300
    },
    h2: {
      fontSize: '19px',
      fontWeight: 300
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 300
    },
    body1: {
      fontSize: '14px',
      fontWeight: 300
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '50vh',
        },
        input: {
          paddingLeft: '2em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
          fontWeight: 300,
          textTransform: 'none',
          borderRadius: '50vh',
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline/>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <React.Suspense fallback={
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress/>
            </Box>
          }>
            <Routes/>
          </React.Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

