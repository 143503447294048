import {atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {Coordinate, MarkerData} from "@hexlabsio/dasha-markers-sdk";
import {MarkerCollection} from "@hexlabsio/dasha-layers-sdk";
// eslint-disable-next-line import/no-cycle
import { useUpdateShownCollections } from '../category/category-state';
import {markersSdk} from "../sdks";
import {jwtToken} from "../user/auth-state";
// eslint-disable-next-line import/no-cycle
import {CollectionResult, findBounds, flyToLocation, SearchArea, searchBounds} from "../shared-state";

export type ButtonResponse = {buttonIdentifier: string; markers: MarkerCollection[]}

export const markerCollectionState = atomFamily<ButtonResponse[], {category: string}>({key: 'Markers', default: () => []});


function uniqueMarkers(collection: MarkerCollection) {
  const matched: string[] = [];
  const locations = collection.member.filter(it => {
    const include = it.coordinate.longitude !== 0 && it.coordinate.latitude !== 0 && !matched.includes(`${it.coordinate.latitude}, ${it.coordinate.longitude}`);
    matched.push(`${it.coordinate.latitude}, ${it.coordinate.longitude}`);
    return include;
  });
return {...collection, member: locations};
}

export function useMarkerCollections(category: string) {
  const jwt = useRecoilValue(jwtToken)!;
  const [, setItems] = useRecoilState(markerCollectionState({category}));
  const flyTo = useSetRecoilState(flyToLocation);
  const searchBox = useRecoilValue(searchBounds);
  return {
    async updateCollection(collection: string, markerCollection: string, searchedArea?: SearchArea): Promise<CollectionResult> {
      try {
        const markers = await markersSdk(jwt).getMarkerCollection({
          category,
          collection: markerCollection
        }, searchedArea?.r ? {latitude: searchedArea.lt, longitude: searchedArea.ln, radius: searchedArea.r} :
            searchedArea?.lt && {minLat: searchedArea.lt, minLong: searchedArea.ln, maxLat: searchedArea.xlt, maxLong: searchedArea.xln} || {}, {});
        if (markers.statusCode === 200) {
          const unique = uniqueMarkers(markers.result);
          setItems(old => {
            const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, markers: []};
            return [...old.filter(it => it.buttonIdentifier !== collection), {...update, markers: [unique]}]
          });
          if(!searchBox)
            flyTo(findBounds(unique.member.map(it => it.coordinate)));

          return {searchedArea, total: unique.member.length}
        }
      } catch(e) {
        return {reason: 'ERROR'};
      }
      return {reason: 'NONE_FOUND'};
    }
  }
}

const markerPropertiesSelector = selectorFamily<MarkerData | undefined, {category: string; collection: string; identifier: string}>({
  key: 'markerPropertiesSelector',
  get: ({category, collection, identifier}) => async ({get}) => {
    const jwt = get(jwtToken)!;
    const data = await markersSdk(jwt).getMarker({category, collection, identifier});
    if(data.statusCode === 200) {
      return data.result
    }
    return undefined;
  }})
export const markerPropertiesState = atomFamily({key: 'markerPropertiesState', default: markerPropertiesSelector});

export const markerPropertiesCollectionSelector = selectorFamily<Array<{identifier: string, data?: MarkerData; coordinate?: Coordinate}>, {category: string; collection: string; identifiers: string[]}>({
  key: 'markerPropertiesSelector',
  get: ({category, collection, identifiers}) => async ({get}) => {
    const jwt = get(jwtToken)!;
    const markers = get(markerCollectionState({category})).flatMap(it => it.markers.flatMap(coll => coll.member));
    const sdk = markersSdk(jwt);
    return Promise.all(identifiers.map(async identifier => {
      const result = await sdk.getMarker({category, collection, identifier});
      if(result.statusCode === 200) {
        const coordinate = markers?.find(it => it.identifier === identifier)?.coordinate;
        return {identifier, data: result.result, coordinate }
      }
      return {identifier}
    }));
  }})

export const markerPropertiesCollectionState = atomFamily({key: 'markerPropertiesCollectionState', default: markerPropertiesCollectionSelector});


export function useSpecialMarker(category: string, key: string){
  const setMarkers = useSetRecoilState(markerCollectionState({category}));
  const setMarkerProperties = useSetRecoilState(markerPropertiesState({category, collection: key, identifier: key}))
  const setShown = useUpdateShownCollections();
  return {
    remove() { setMarkers(old => old.filter(it => it.buttonIdentifier !== key))},
    set(collection: MarkerCollection, properties: any) {
      setMarkerProperties({identifier: '', markerProperties: properties});
      setMarkers(old => [...old.filter(it => it.buttonIdentifier !== key), {buttonIdentifier: key, markers: [collection]}]);
      setShown(old => [...old.filter(it => it.id !== key), {id: key}])
    }
  }
}